import React, { useContext, useState } from 'react';
import { Link, Outlet } from 'react-router-dom';
import { AuthContext } from '../AuthContext';
import DBBackupButton from './DBBackupButtonComponent';
import { FaBars } from 'react-icons/fa';

const Menu = () => {
    const jwtToken = localStorage.getItem('JWToken');
    const { isLoggedIn, userRole } = useContext(AuthContext);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        if (window.innerWidth < 768) {
            setIsMenuOpen(!isMenuOpen);
        }
    };

    return (
        <div className="flex">
            {/* Bouton pour afficher/masquer le menu */}
            <button 
                className="md:hidden fixed bottom-4 right-4 z-50 bg-yellow-500 text-black p-2 rounded-md"
                onClick={toggleMenu}
            >
                <FaBars />
            </button>

            {/* Menu latéral */}
            <nav className={`bg-black p-4 w-80 md:w-40 flex flex-col gap-1 fixed h-full z-40 transform ${isMenuOpen ? 'translate-x-0' : '-translate-x-full'} md:translate-x-0 transition-transform duration-300 ease-in-out`}>
                <Link to="/admin/products" className="text-white px-3 py-2 rounded-md text-sm font-medium hover:bg-yellow-600" onClick={toggleMenu}>Produits</Link>
                <Link to="/admin/brands" className="text-white px-3 py-2 rounded-md text-sm font-medium hover:bg-yellow-600" onClick={toggleMenu}>Marques</Link>
                <Link to="/admin/features" className="text-white px-3 py-2 rounded-md text-sm font-medium hover:bg-yellow-600" onClick={toggleMenu}>Caractéristiques</Link>
                <Link to="/admin/users" className="text-white px-3 py-2 rounded-md text-sm font-medium hover:bg-yellow-600" onClick={toggleMenu}>Utilisateurs</Link>
                {isLoggedIn && userRole === 'superadmin' && <DBBackupButton className="text-white px-3 py-2 rounded-md text-sm font-medium hover:bg-yellow-600"/>}
            </nav>

            {/* Fond flouté */}
            {isMenuOpen && (
                <div 
                    className="fixed inset-0 bg-black bg-opacity-50 z-30"
                    onClick={toggleMenu}
                ></div>
            )}

            {/* Contenu principal */}
            <div className="md:ml-40 w-full h-full bg-gray-100">
                <Outlet />
            </div>
        </div>
    );
};

export default Menu;