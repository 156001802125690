import React, { useContext } from 'react';
import { AuthContext } from '../components/AuthContext';
import HomePageForProComponents from '../components/HomePageForProComponents';
import FooterTrust from '../components/FooterTrustComponents';
import FilterAndProductList from '../components/filter-with-list-components/FilterAndProductList';
import GuestMessage from '../components/GuestMessage'; // Importer le nouveau composant

const HomePage = () => {
  const { isLoggedIn, userRole } = useContext(AuthContext);

  return (
    <>
      <div className="bg-gray-100" style={{ minHeight: "84vh" }}>
        <HomePageForProComponents/>
        {userRole === 'guest' ? (
          <GuestMessage/>
        ) : (
          <FilterAndProductList/>
        )}
        <FooterTrust/>
      </div>
    </>
  );
};

export default HomePage;