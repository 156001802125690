import React, { useState, useContext } from 'react';
import { AuthContext } from '../../components/AuthContext';
import { FaExclamationTriangle } from 'react-icons/fa';
import ProductListRow from './ProductListRow';
import EditProductModal from './new-product-modal/EditProductModal';

const ProductList = ({ products, handleEditProduct }) => {
    const [selectedProduct, setSelectedProduct] = useState(null);
    const { userRole } = useContext(AuthContext);

    const getProductSpecificationValue = (specifications, specName) => {
        const spec = specifications.find(spec => spec.specification.name === specName);
        return spec ? spec.value : 'N/A'; // Retourne la valeur si elle existe, sinon 'N/A'
    };

    const handleRowClick = (product) => {
        setSelectedProduct(product);
    };

    const handleCloseModal = () => {
        setSelectedProduct(null);
    };

    return (
        <div className='flex flex-col gap-2'>
            <div className="bg-[#ffffff] border shadow rounded-md h-auto p-4">
                <div className="grid grid-cols-12 gap-4 text-center font-bold">
                    <div className="hidden md:block col-span-2">Taille</div>                    
                    <div className="col-span-2">Type</div>
                    <div className="col-span-3 md:col-span-1">Marque</div>
                    <div className="hidden md:block col-span-2">Désignation</div>
                    <div className="col-span-3 md:col-span-2">Référence</div>
                    <div className="hidden md:block col-span-1">EAN</div>
                    <div className="md:col-span-1 col-span-2">Prix</div>
                    <div className="col-span-1">Stock</div>
                </div>
            </div>
            {products.length === 0 ? (
                <div className="flex flex-col items-center justify-center mt-4">
                    <FaExclamationTriangle className="text-red-500 text-4xl mb-2" />
                    <p className="text-red-500 text-lg">Désolé, aucun produit ne correspond à votre recherche.</p>
                </div>
            ) : (
                products.map((product, index) => (
                    <div key={index} onClick={() => handleRowClick(product)} className={(userRole === 'admin' || userRole === 'superadmin') ? 'cursor-pointer border-2 hover:border-yellow-400' : ''}>
                        <ProductListRow
                            productSize={getProductSpecificationValue(product.specifications, "Taille de produit")} // Taille de produit
                            productType={getProductSpecificationValue(product.specifications, "Type de produit")} // Type de produit
                            Brand={product.brand ? product.brand.brand : 'Marque inconnue'} // Si la marque existe
                            designation={product.designation}
                            reference={product.reference}
                            ean={product.ean}
                            price={product.price}
                            stock={product.stock}
                        />
                    </div>
                ))
            )}
            {selectedProduct && (userRole === 'admin' || userRole === 'superadmin') && (
                <EditProductModal onEdit={handleEditProduct} product={selectedProduct} onClose={handleCloseModal} />
            )}
        </div>
    );
};

export default ProductList;