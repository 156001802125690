import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../components/AuthContext';
import HomePageForProComponents from '../components/HomePageForProComponents';
import FilterAndProductList from '../components/filter-with-list-components/FilterAndProductList';
import GuestMessage from '../components/GuestMessage'; // Importer le nouveau composant

const HomePage = () => {
  const { isLoggedIn, userRole } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/login');
    }
  }, [isLoggedIn, navigate]);

  return (
    <>
      <div className="bg-gray-100 pb-10" style={{ minHeight: "84vh" }}>
        <HomePageForProComponents/>
        {userRole === 'guest' ? (
          <GuestMessage/>
        ) : (
          <FilterAndProductList/>
        )}
      </div>
    </>
  );
};

export default HomePage;