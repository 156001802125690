import React from 'react';

const OrderList = () => {
  // Générer des données aléatoires pour les commandes
  const orders = [
    { id: '001', product: 'Produit 1', price: 120, date: '2024-01-01' },
    { id: '002', product: 'Produit 2', price: 85, date: '2024-02-15' },
    { id: '003', product: 'Produit 3', price: 200, date: '2024-03-20' },
    { id: '004', product: 'Produit 4', price: 150, date: '2024-04-10' },
  ];

  return (
    <div>
      <h2 className="text-xl font-bold mb-4">Vos commandes</h2>
      <ul className="space-y-2">
        {orders.map(order => (
          <li key={order.id} className="p-4 border rounded-md bg-gray-100">
            <p><strong>ID Commande :</strong> {order.id}</p>
            <p><strong>Produit :</strong> {order.product}</p>
            <p><strong>Prix :</strong> {order.price} €</p>
            <p><strong>Date :</strong> {order.date}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default OrderList;