import React, { useState, useEffect, useContext } from 'react';

// Autres imports ...
const ProductListRow = ({ productSize, productType, Brand, designation, reference, ean, price, stock }) => {
    return (
        <div className="bg-[#ffffff] border shadow rounded-md h-auto p-4">
            <div className="grid grid-cols-12 gap-4 text-center">
                <div className="hidden md:block col-span-2 overflow-auto">{productSize}</div>
                <div className="col-span-2 overflow-auto">{productType}</div>
                <div className="col-span-3 md:col-span-1 overflow-auto">{Brand}</div>
                <div className="hidden md:block col-span-2 overflow-auto">{designation}</div>
                <div className="col-span-3 md:col-span-2 overflow-auto">{reference}</div>
                <div className="hidden md:block col-span-1 overflow-auto">{ean}</div>
                <div className="md:col-span-1 col-span-2 overflow-auto">{price}</div>
                <div className="md:col-span-1 col-span-2 overflow-auto text-red-600">{stock}</div>
            </div>
        </div>

    );
};

export default ProductListRow;