import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../../api/api';
import { AuthContext } from '../../components/AuthContext'; 
import { FaUser, FaBuilding, FaBriefcase, FaEnvelope, FaLock } from 'react-icons/fa'; 
import OrderList from '../../components/user-profile-components/OrderList'; // Composant pour lister les commandes

const ProfilePage = () => {
  const navigate = useNavigate();
  const { logOut } = useContext(AuthContext);
  const [userInfo, setUserInfo] = useState({
    _id: '',
    firstName: '',
    lastName: '',
    companyName: '',
    email: '',
    role: '',
    password: ''
  });
  const [editableUserInfo, setEditableUserInfo] = useState(userInfo);
  const [isUpdated, setIsUpdated] = useState(false); // Pour gérer l'effet visuel après la mise à jour
  const [hasError, setHasError] = useState(false); // Pour gérer l'effet visuel en cas d'échec de la mise à jour
  const [passwordVisible, setPasswordVisible] = useState(false);

  useEffect(() => {
    fetchUserInfo();
  }, []);

  const fetchUserInfo = async () => {
    try {
      const response = await api.getUserInfo();
      const data = response.data;
      setUserInfo({
        _id: data._id,
        firstName: data.firstName,
        lastName: data.lastName,
        companyName: data.companyName,
        email: data.email,
        role: data.role,
        password: '******' 
      });
      setEditableUserInfo({
        firstName: data.firstName,
        lastName: data.lastName,
        companyName: data.companyName,
        email: data.email,
        password: ''
      });
    } catch (error) {
      console.error('Erreur lors de la récupération des informations de l\'utilisateur:', error);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setEditableUserInfo({ ...editableUserInfo, [name]: value });
  };

const handleEditProfile = async () => {
    const body = { ...editableUserInfo };
    if (body.password === '') {
        delete body.password; 
    }

    // Check if any required field is empty
    const requiredFields = ['firstName', 'lastName', 'companyName', 'email'];
    const emptyFields = requiredFields.filter(field => body[field] === '');
    if (emptyFields.length > 0) {
        console.error('Erreur: Veuillez remplir tous les champs obligatoires');
        setHasError(true);
        setTimeout(() => setHasError(false), 1000); // Réinitialiser après 1 seconde
        return;
    }

    try {
        await api.updateUser(userInfo._id, body);
        fetchUserInfo(); 
        setIsUpdated(true);
        setHasError(false);
        setTimeout(() => setIsUpdated(false), 1000); // Réinitialiser après 1 seconde
    } catch (error) {
        console.error('Erreur lors de la mise à jour du profil:', error);
        setHasError(true);
        setTimeout(() => setHasError(false), 1000); // Réinitialiser après 1 seconde
    }
};

  const handleLogout = async () => {
    try {
      await api.logout();
      logOut();
      localStorage.clear();
      document.cookie.split(";").forEach((c) => {
        document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
      });
      navigate('/login');
    } catch (error) {
      console.error('Erreur lors de la déconnexion:', error);
    }
  };

  return (
    <div className='flex justify-center bg-gray-100' style={{ minHeight: "83vh" }}>
      {/* Container with profile and orders */}
      <div className="container mx-auto p-4 my-8 bg-white shadow-lg rounded-lg flex flex-col md:flex-row max-w-5xl">
        
        {/* Left side: Profile Form */}
        <div className="flex-1 p-4">
          <div className="flex flex-col items-center">
            <div className="mb-4 bg-yellow-500 w-full py-10 flex items-center justify-center rounded-t-lg">
              <FaUser className="text-white h-16 w-16" />
            </div>
            <div className="w-full flex flex-col p-4">
                <div className="flex items-center border-b py-2">
                    <FaBriefcase className="text-gray-400 mr-2" />
                    <span className="p-1">Status: </span>
                    <span className="p-1 flex-grow">{userInfo.role}</span> {/* Le rôle est affiché, non modifiable */}
                </div>
              <div className="flex items-center border-b py-2">
                <FaUser className="text-gray-400 mr-2" />
                <span className="p-1">Prénom: </span>
                <input
                  type="text"
                  name="firstName"
                  value={editableUserInfo.firstName}
                  onChange={handleInputChange}
                  className="border-2 rounded-md p-1 flex-grow"
                />
              </div>
              <div className="flex items-center border-b py-2">
                <FaUser className="text-gray-400 mr-2" />
                <span className="p-1">Nom: </span>
                <input
                  type="text"
                  name="lastName"
                  value={editableUserInfo.lastName}
                  onChange={handleInputChange}
                  className="border-2 rounded-md p-1 flex-grow"
                />
              </div>
              <div className="flex items-center border-b py-2">
                <FaBuilding className="text-gray-400 mr-2" />
                <span className="p-1">Société: </span>
                <input
                  type="text"
                  name="companyName"
                  value={editableUserInfo.companyName}
                  onChange={handleInputChange}
                  className="border-2 rounded-md p-1 flex-grow"
                />
              </div>
              <div className="flex items-center border-b py-2">
                <FaEnvelope className="text-gray-400 mr-2" />
                <span className="p-1">Email: </span>
                <input
                  type="email"
                  name="email"
                  value={editableUserInfo.email}
                  onChange={handleInputChange}
                  className="border-2 rounded-md p-1 flex-grow"
                />
              </div>
              <div className="flex items-center border-b py-2">
                <FaLock className="text-gray-400 mr-2" />
                <span className="p-1">Mot de passe: </span>
                <input
                  type="password"
                  name="password"
                  placeholder="******"
                  value={editableUserInfo.password}
                  onChange={handleInputChange}
                  className="border-2 rounded-md p-1 flex-grow"
                />
              </div>
            </div>
            <button
              onClick={handleEditProfile}
              className={`font-bold py-2 px-4 rounded w-full mt-4 ${
                isUpdated ? 'bg-green-400 hover:bg-green-700 text-white' 
                : hasError ? 'bg-red-400 hover:bg-red-700 text-white' 
                : 'bg-yellow-500 hover:bg-yellow-700 text-white'
              }`}
            >
              Mettre à jour le profil
            </button>
            <button
              onClick={handleLogout}
              className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded w-full mt-4"
            >
              Déconnexion
            </button>
          </div>
        </div>

        {/* Vertical divider */}
        <div className="border-l-2 mx-4"></div>

        {/* Right side: Orders */}
        <div className="flex-1 p-4">
          <OrderList />
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;