import React, { useContext } from 'react';
import { Link, Outlet } from 'react-router-dom';
import { AuthContext } from './AuthContext';
import profile from '../assets/profile.png';
import { useLocation } from 'react-router-dom';

const Menu = () => {
  const jwtToken = localStorage.getItem('JWToken');
  const { isLoggedIn, userRole } = useContext(AuthContext);
  const location = useLocation();

  return (
    <div className="flex z-100">
      <nav className="flex justify-between items-center fixed top-0 left-0 right-0 z-10 bg-black p-4">
        <div className="flex items-center">
          {isLoggedIn && <Link to="/"><img src="https://destockdis.com/img/destockdis.svg" alt="Logo" className="h-12 w-36 mr-4 visible sm:block" /></Link>}
          {!isLoggedIn && <img src="https://destockdis.com/img/destockdis.svg" alt="Logo" className="h-12 w-36 mr-4 visible sm:block" />}
          {isLoggedIn && (userRole === "admin" || userRole === "superadmin") && <Link to="/admin/products" className="text-white px-3 py-2 rounded-md text-lg leading-6 font-bold hover:bg-yellow-600">Admin Dashboard</Link>}
        </div>
        <div className="flex items-center">
          {!isLoggedIn && location.pathname !== "/login" && <Link to="/login" className="text-black px-3 py-2 rounded-md text-sm font-medium bg-yellow-500 hover:bg-yellow-600">Se connecter</Link>}
          {isLoggedIn && <Link to="/profile" className="ml-4 flex p-0.5 items-center justify-center bg-yellow-500 text-white rounded-full h-12 w-12 hover:bg-yellow-300">
              <img src={profile} alt="Profile" className="p-1 h-full w-full" />
          </Link>}
        </div>
      </nav>
      <div className="mt-4 w-full h-full">
        <Outlet/>
      </div>
    </div>
  );
};

export default Menu;
